<template>
  <div>
    <v-img
      style="height:82vh;top:-20px;"
      aspect-ratio="16/9"
      cover
      src="./img/bg-energy.jpg"
    ></v-img>
  </div>
</template>
<script>
  export default {
    data: function () {
      return {

      }
    },
  }
</script>
<style scoped>
    .blur {
      backdrop-filter: blur(10px);
    }
</style>